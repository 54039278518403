<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.联系电话')" prop="mobile">
                <a-input v-model="queryParam.mobile" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.联系电话')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.姓名')" prop="realName">
                <a-input v-model="queryParam.realName" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.姓名')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.广告名称')" prop="adsName">
                <a-input v-model="queryParam.advertName" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.广告名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('广告投放审核.广告活动开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                                 :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('广告投放审核.广告活动结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                                 :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('广告投放审核.审核状态')" prop="status">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('广告投放审核.审核状态')" style="width: 100%" v-model="queryParam.status" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.AdvertApplyStatusEnum" :value="item.type+''"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('广告投放审核.审核失败原因')" prop="failReason">-->
<!--                  <a-input v-model="queryParam.failReason" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.审核失败原因')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('广告投放审核.审核人')" prop="examineName">-->
<!--                  <a-input v-model="queryParam.examineName" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.审核人')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('广告投放审核.审核时间')" prop="examineTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.examineTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['advert:apply:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['advert:apply:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        @change="handleSortChange"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
         <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.AdvertApplyStatusEnum" :value="record.status"/>
        </span>

        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="examineTime" slot-scope="text, record">
          {{ parseTime(record.examineTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.status===1" v-hasPermi="['advert:apply:audit']">
             <a-icon type="edit" />{{$t('通用.按钮.审核')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment';
import { pageAdvertisement,listAdvertisement, delAdvertisement } from '@/api/advert/apply'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Advertisement',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        mobile: null,
        realName: null,
        advertName: null,
        describe: null,
        createBeginTime: null,
        createEndTime: null,
        status: null,
        failReason: null,
        examineName: null,
        examineTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('广告投放审核.姓名'),
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.联系电话'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告名称'),
          dataIndex: 'advertName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告简介'),
          dataIndex: 'describe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告活动开始时间'),
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告活动结束时间'),
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核状态'),
          dataIndex: 'statusName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('广告投放审核.审核状态描述 审核中,已通过待投放,已投放,已结束'),
          dataIndex: 'statusDescribe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核失败原因'),
          dataIndex: 'failReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核人'),
          dataIndex: 'examineName',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('广告投放审核.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核时间'),
          dataIndex: 'examineTime',
          scopedSlots: { customRender: 'examineTime' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('广告投放审核.插入时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.备注'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询广告投放审核列表 */
    getList () {
      this.loading = true
     pageAdvertisement(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        mobile: undefined,
        realName: null,
        advertName: null,
        describe: undefined,
        startTime: undefined,
        endTime: undefined,
        status: undefined,
        statusDescribe: undefined,
        failReason: undefined,
        examineName: undefined,
        examineTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAdvertisement(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('advert/apply/export',
            that.queryParam
          , `广告投放审核_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('advert/apply/export', queryParam, `广告投放审核_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>
